












































import { Component, Vue } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import {
  BLink, BImg, BSpinner, BForm, BFormInput, BFormGroup, BButton, BCardText, BCardTitle, BCard, BInputGroup, BInputGroupAppend,BAlert
} from 'bootstrap-vue'
import VuexyLogo from '@/@core/layouts/components/Logo.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { confirmed, password, required } from "@/@core/utils/validations/validations";
import { UtilisateurValidateEmailModel } from "@/api/models/utilisateurs/utilisateur";
@Component({
  components: {
    BForm,
    BAlert,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BImg,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BCardText,
    BCard,
    BInputGroup,
    BInputGroupAppend
  },
})
export default class ConfirmEmail extends Vue {
  required = required;
  password= password;
  confirmed = confirmed;
  message = "";
  variant = "";
  
  payload: UtilisateurValidateEmailModel = {}
  loading = false

  passwordFieldType = "password"
  confirmPasswordFieldType = "password"
  
  created() {
    this.payload = {
      userId: this.$route.params.userId,
      emailToken: this.$route.params.emailToken,
    }
    this.validate();
  }
  

  async validate() {
    this.loading = true
    await this.$http.myressif.utilisateurs
      .askConfirmEmail(this.payload)
      .then((res: any) => {
        this.message = "Votre email à été validé avec succés !"
        this.variant = "success";
        this.loading = false
      },
      (error: any) => {
        this.loading = false
        this.variant = "danger";
        this.message = "Il y'a eu un soucis avec la validation de votre email"

        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      })
  }

}
